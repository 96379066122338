import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

function CheckoutSuccessPage() {
  return (
    <Layout>
      <SEO title="Checkout Success" />
      <div className="py-12 bg-white">
        <div className="max-w-full md:max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
            Success
          </p>
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Thank you!
          </h3>
          <p className="mt-4 text-xl leading-7 text-gray-500 lg:mx-auto">
            I will begin monitoring, perform an initial analysis, and email you
            within 24 hours about what to expect. Feel free to reach out to me
            in the mean time. -Jamie
          </p>
          <p className="mt-4 text-xl leading-7 text-gray-500 lg:mx-auto">
            <a href="mailto:jamie@spectatin.com">jamie@spectatin.com</a>
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default CheckoutSuccessPage;
